// 自定义类名
.body--dark {
  .bit-container {
    background-color: $mainBgColor !important;
    box-shadow: 0 2px 4px 0 $tableRow !important;
  }
  .bit-table .bit-table__header {
    color: $white !important;
    background: rgba($tableRow, 0.5) !important;
    box-shadow: none !important;
  }
  .bit-table .bit-table__body > .row {
    box-shadow: none !important;
    background: rgba($tableRow, 0.5) !important;

    &:hover {
      cursor: pointer;
      background-color: rgba($tableRow, 0.9) !important;
    }
  }
  // 首页
  .dashboard {
    .theme-package-item {
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.5) !important;
    }
  }
  .form-container .form-lead {
    color: $white !important;
  }
  // 菜单和工具栏
  .theme-cancel-btn {
    background-color: $cancelBtn !important;
  }
  .theme-change-package:hover,
  .theme-change-package.active,
  .links .link-item:hover {
    background-color: $hoverColor !important;
  }
  .theme-opening-progress,
  .theme-user-list,
  .theme-cycle.active,
  .theme-cycle:hover {
    background-color: $bgColor666 !important;
  }
  .theme-minimize {
    color: $white !important;
  }
  .theme-font-black {
    color: $open-font-color;
  }
  .theme-page-body,
  .theme-recommended,
  .theme-version-info,
  .theme-login-container,
  .theme-container,
  .theme-card,
  .theme-header-bg,
  .theme-white,
  .profile .no-bg > .col {
    background-color: $mainBgColor !important;
  }
  .theme-payment-item {
    // background-color: $white !important;
  }
  .theme-title {
    color: $white !important;
  }
  .theme-primary {
    color: $primary;
  }
  .theme-user-list {
    .q-item.activeTransfer {
      background: #858585;
    }
  }
}
// 覆盖quasar默认的暗色模式样式
body.body--dark {
  color: $darkColor;
  background-color: var(--q-dark-page);

  .q-dialog__backdrop {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .q-dialog .container,
  .q-dark {
    background-color: $mainBgColor;
  }
  .dialog-container {
    background-color: $mainBgColor;
    & > div:first-child {
      color: $white !important;
    }
  }
  .q-menu--dark,
  .bg-grey-3 {
    background-color: $bgColor666 !important;
  }
  .q-item.q-router-link--active,
  .q-item--active {
    color: orange;
  }
  .q-router-link--active {
    background-color: $primary;
  }
  .bg-grey-2 {
    background-color: $dark-grey2 !important;
  }
  .bg-orange-1 {
    background-color: rgba(255, 243, 224, 0.5) !important;
  }
  .text-grey-8 {
    color: $dark-grey8 !important;
  }
  .text-grey-10,
  .theme-sub-sysnotice.text-primary {
    color: $darkColor !important;
  }
  .text-primary,
  .theme-sysnotice.text-primary,
  .bit-form-before {
    color: $white !important;
  }
  .q-checkbox__inner.text-primary,
  .q-linear-progress.text-primary,
  .q-badge.text-primary,
  .theme-useBalance.text-primary,
  .theme-ostype > .text-primary {
    color: $primary !important;
  }
  .bit-custom-toggle {
    background-color: $toggle-color;
  }
  .text-orange-9 {
    color: $orange-9 !important;
  }
  .border-color {
    border-color: rgba(255, 255, 255, 0.28);
  }
  .line-bg {
    background: $gray-light;
  }
  .batch-progress {
    background: rgba(73, 73, 73, 0.5);
    color: white;
  }
}
