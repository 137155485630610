@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import './bodylight.scss';
@import './bodydark.scss';
// app global css in SCSS form
html,
body,
#q-app {
  min-height: 100vh;
  // font-family: 'PingFangSC-Regular', 'SourceHanSansCN Regular', 'Microsoft YaHei Regular', 'Montserrat Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0;
  &:hover {
    width: 4px;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

/*定义滚动条轨道：内阴影+圆角*/
::-webkit-scrollbar-track {
  width: 2px;
  background-color: var(--q-dark);
}
/*定义滑块：内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #a8a8a8;

  &:active {
    background-color: #787878;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: 1.5;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

ul,
ul li,
dl,
dt,
dd {
  list-style: none;
  margin: 0;
  padding: 0;
}

// 业务wrapper box
.bit-container {
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  min-height: 100%;
}

.form-container {
  padding: 0 20px;
  border-left: 2px solid $primary;
  .form-lead {
    color: $primary;
    font-weight: bold;
    position: relative;
    margin-bottom: 10px;
    height: 16px;
    line-height: 16px;
    &::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      left: -29px;
      top: 0;
      background: $primary;
      border-radius: 8px;
    }
  }
  .form-label {
    margin-top: 10px;
    color: #464646;
  }
}

// form before
.bit-form-before {
  width: 100px;
  font-size: 12px;
  padding-left: 10px;

  &.required {
    position: relative;
    &::before {
      content: '*';
      color: red;
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 2px;
    }
  }
}

.bit-form-before-short {
  width: 70px;
  font-size: 12px;
  padding-left: 10px;
  color: #000;
  &.required {
    position: relative;
    &::before {
      content: '*';
      color: red;
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 2px;
    }
  }
}

// Reset Quasar
.q-field--dense .q-field__control,
.q-field--dense .q-field__marginal {
  height: 32px;
}

.q-field--with-bottom {
  padding-bottom: 24px;
}

// 把textarea恢复auto
.q-textarea .q-field__control {
  height: auto;
}

.q-field--auto-height.q-field--dense .q-field__control,
.q-field--auto-height.q-field--dense .q-field__native {
  min-height: 32px;
}

.q-field__marginal {
  font-size: 16px;
  color: #595959;
}

.q-checkbox__bg {
  border: 1px solid #ccc;
}

// .q-item {
//   min-height: 40px;
// }
.q-field__label {
  font-size: 14px;
}

.q-chip {
  font-size: 12px;
  margin: 2px 4px;
}

.q-field__input {
  min-height: 20px;
  line-height: 20px;
}

.font-12 {
  font-size: 12px;
}

.bit-custom-toggle {
  // border: 1px solid #999;
  border-radius: 3px;
  overflow: hidden;
  background: #f1f1f1;
}

.q-btn .q-icon,
.q-btn .q-spinner {
  font-size: 16px;
}

.bit-clear {
  color: #9b9b9b;
  &:hover {
    color: #595959;
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.q-mb-super-lg {
  margin-bottom: 40px;
}

.bit-active-btn {
  &::after {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: url('~assets/btn-active-icon.svg') no-repeat right bottom;
  }
}

.q-notifications__list--top {
  top: 30px;
}

.q-tab {
  padding: 0 8px;
}


.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: $breakpoint-sm-min) {
  .q-dialog__inner--minimized > div {
    max-width: 1200px;
  }
}

.sys-notice img {
  max-width: 100%;
}

.sys-notice p {
  line-height: 26px !important;
}

.q-drawer--mini {
  width: 58px !important;
}

.q-bar--standard {
  height: 40px;
}
.phone-custom-pop {
  padding: 10px;
  border-radius: 4px;
  max-width: 300px !important;

  color: #fafafa;
  background: #757575;
  font-weight: normal;
}
